import React, { useState, useEffect, useContext } from "react"
import {
  Box,
  Text,
  Menu,
  MenuButton,
  MenuList,
  MenuOptionGroup,
  MenuDivider,
  Avatar,
  Stack,
  Badge,
  MenuItem,
  Spinner
} from "@chakra-ui/react"
import { retrieveCustomerPortalSession, retrieveSubScription, getUserInfo } from "../../utils/Utils"
import { useRouter } from "next/router"
import { useAuth } from "../context/Auth"
import {
  SelectedMenu,
} from "../context/DomainContext"

export default function ProfileMenu() {

  const location = useRouter()
  const { signOutUser } = useAuth()
  const [subData, setSubData] = useState()
  const SelectedMenuVal = useContext(SelectedMenu)
  const [userProfileInfo, setUserProfileInfo] = useState({})
  const [userProfileLoaded, setUserProfileLoaded] = useState(false)

  const fetchData = async () => {
    setUserProfileLoaded(false)
    const userProfileInfo = await getUserInfo()
    setUserProfileLoaded(true)
    setUserProfileInfo(userProfileInfo)
    const getSubsriptionDetails = await retrieveSubScription()
    setSubData(getSubsriptionDetails?.subscriptionData?.name)
  }

  

  useEffect(() => {
    fetchData()
  }, [])

  const navigateToCustomerProtal = async () => {
    const data = await retrieveSubScription()
   
    if (data.subscriptionData != null) {
      const res = await retrieveCustomerPortalSession()
      if (!res?.url) return null

      return location.push({ pathname: res?.url })

    } else {
      return location.push({ pathname: "/pricing" })
    }
  }

  return (
    <Menu closeOnSelect={false} key={[SelectedMenuVal?.menuName, subData]}>
      <MenuButton key={"menuBtn"}

      >
        {
          userProfileLoaded ?
            <Avatar name={userProfileInfo?.firstName + " " + userProfileInfo?.lastName} size='sm' key="avatar1" />
            :
            <Spinner size={"xs"} />
        }
      </MenuButton>
      <MenuList minWidth='200px' key={subData} padding="0" boxShadow="rgb(0 0 0 / 5%) 0px 0px 10px 0px">

        <Box
          display="flex"
          flexDirection="row"
          p={2}
          alignItems="center"
          gap={2}
          position="relative"
          left={0}
        >
          {
            userProfileLoaded ?
              <Avatar name={userProfileInfo?.firstName + " " + userProfileInfo?.lastName} size='sm' key="avatar2" />
              :
              <Spinner size={"xs"} />
          }
          <Box
            display="flex"
            flexDirection="column"
            ml="5px"
          >
            <Text
              textTransform="capitalize"
              fontSize={12}
              fontWeight="600"
              mb="5px"
            >
              {userProfileInfo?.firstName + " " + userProfileInfo?.lastName}
            </Text>
            {
              <Stack direction='row' key={subData} fontWeight="500">
                <Badge boxShadow="none" lineHeight="17px" border="1px solid" colorScheme='green' textTransform="uppercase" fontSize="9px">
                  {subData ? subData : "Free"}
                </Badge>
              </Stack>
            }
          </Box>
        </Box>
        <MenuDivider />
        <MenuOptionGroup title='Subscription' fontSize="14px" ml="10px">
          <MenuItem fontSize="14px" key="manageSub" onClick={() => { navigateToCustomerProtal() }}>Manage Subscription</MenuItem>
        </MenuOptionGroup>
        <MenuItem fontSize="14px"
          key="signOut"
          value='Sign Out'
          onClick={async () => {
            await signOutUser()
            location?.reload("/")
          }}
        >
          Sign Out
        </MenuItem>
      </MenuList>
    </Menu>
  )
}

