import React, { useContext, createContext, useEffect } from "react"
import Client from "../../ApolloClient"
import { SIGNUP, FORGET_PASSWORD, RESET_PASSWORD } from "../../graphql/mutations"
import { signOut, useSession } from "next-auth/react"
import { isClientSide } from "../../utils/Utils"
import { Box } from "@chakra-ui/react"
import logoIcon from "../../public/default-logo.png"
import Image from "next/image"


const authContext = createContext()

function AuthProvider({ children }) {
  const auth = useProvideAuth()
  const sessionStatus = auth.authStatus()
  const session = auth.userInfo()
  useEffect(() => {
    async function checkSession() {
      if (session?.error === "RefreshAccessTokenError") {
        await signOut({ callbackUrl: window.location.pathname }) // Force signout in to hopefully resolve error
      }
    }
    checkSession()
  }, [sessionStatus, session])
  return (
    <authContext.Provider value={auth}>
      {
        (isClientSide() && sessionStatus === "loading") ? (
          <Box width="100%" height="100vh" alignItems="center" justifyContent="center" display="flex">
            <Box>
              <Image
                width="180px"
                height="34px"
                src={logoIcon}
                cursor="pointer"
                mr="0px"
                alt="tracksellers.com logo"
              />
              <br />
              Loading ...
            </Box>

          </Box>
        ) : (children)
      }
    </authContext.Provider>
  )
}

export const useAuth = () => {
  return useContext(authContext)
}

function useProvideAuth() {
  const { data: session, status } = useSession()

  const isSignedIn = () => {
    if (session) return true
    return false
  }

  const userInfo = () => {
    if (!session) return null
    return session
  }

  const authStatus = () => {
    return status
  }

  const signUp = async ({ firstName, lastName, email, password, confirmPassword }) => {
    try {
      const res = await Client.mutate({
        mutation: SIGNUP,
        variables: {
          email: email,
          password1: password,
          password2: confirmPassword,
          username: email,
          firstName: firstName,
          lastName: lastName
        }
      })
      if (res?.data?.register?.success) return { status: 200 }

      return { status: 500, response: res?.data }
    }
    catch (err) {
      return { status: 409 }
    }
  }

  const signOutUser = async () => {
    await signOut({ callbackUrl: window.location.pathname })
  }
  const forgetPassword = async (email) => {
    const res = await Client.mutate({
      mutation: FORGET_PASSWORD,
      variables: {
        email: email
      }
    })
    if (res?.data?.sendPasswordResetEmail?.success) return { status: 200 }
    return { status: 500, response: res?.data }

  }
  const resetPassword = async (token, newPassword1, newPassword2) => {
    const res = await Client.mutate({
      mutation: RESET_PASSWORD,
      variables: {
        token: token,
        newPassword1: newPassword1,
        newPassword2: newPassword2
      }
    })
    if (res?.data?.passwordReset?.success) return { status: 200 }
    return { status: 500, response: res?.data }

  }
  return {
    isSignedIn,
    signOutUser,
    signUp,
    userInfo,
    forgetPassword,
    resetPassword,
    authStatus
  }
}

export {
  AuthProvider,
  authContext
}