import Header from "../header/Header"
import Footer from "../footer/Footer.js"
import { useRouter } from "next/router"
import { DomainContextProvider } from "../context/DomainContext"
import React from "react"

const headerJson = [
  {
    page: "seller-intelligence-report",
    data: {
      subTitle: "Seller Intelligence Reporte",
      subTitleVisible: false,
      suMD: false,
      suTitle: ""
    },
    documentTitle: "TrackSellers | Seller Intelligence Report"
  },
  {
    page: "about",
    data: {
      subTitle: "About Us",
      subTitleVisible: true,
      suMD: true,
      suTitle: "Our Mission - To build a world-class marketplace intelligence platform",
      seoTag: true
    },
    documentTitle: "TrackSellers | About Us"
  },
  {
    page: "faq",
    data: {
      subTitle: "Frequently Asked Questions",
      subTitleVisible: true,
      suMD: false,
      suTitle: "",
      seoTag: true
    },
    documentTitle: "TrackSellers | FAQ"
  },
  {
    page: "research",
    data: {
      subTitle: "RESEARCH ARTICLES",
      subTitleVisible: true,
      suMD: false,
      suTitle: ""
    },
    documentTitle: "TrackSellers | Research"
  },
  {
    page: "sellers/by-domain/[country]",
    data: {
      subTitle: "Top Seller in USA - Amazon.com Marketplace",
      subTitleVisible: true,
      suMD: false,
      suTitle: ""
    },
    documentTitle: "Top Sellers In USA- Amazon.com Marketplace"
  },
  {
    page: "sellers/by-category/[...slug]",
    data: {
      subTitle: "Top Sellers In CDS & VINYL Category On Amazon.com",
      subTitleVisible: true,
      suMD: false,
      suTitle: ""
    },
    documentTitle: "Top Sellers In CDs & Vinyl category on Amazon.com"
  },
  {
    page: "sellers/[id]/[product]/[name]",
    data: {
      subTitle: "Seller Dashboard",
      subTitleVisible: true,
      suMD: false,
      suTitle: ""
    }
  },
  {
    page: "terms-and-conditions",
    data: {
      subTitle: "Terms and Conditions for TrackSellers",
      subTitleVisible: true,
      suMD: true,
      suTitle: ""
    }
  },
  {
    page: "payments/cancelled/[...seller]",
    data: {
      subTitle: "SELLER INTELLIGENCE REPORT",
      subTitleVisible: true,
      suMD: true,
      suTitle: ""
    }
  },
  {
    page: "payments/success/[...seller]",
    data: {
      subTitle: "SELLER INTELLIGENCE REPORT",
      subTitleVisible: true,
      suMD: true,
      suTitle: ""
    }
  },
  {
    page: "sellers/search",
    data: {
      subTitle: "Advanced Seller Search",
      subTitleVisible: true,
      suMD: false,
      suTitle: ""
    },
    documentTitle: "Advanced Seller Search"
  },
  {
    page: "/pricing",
    data: {
      subTitle: "",
      subTitleVisible: false,
      suMD: false,
      suTitle: ""
    },
    documentTitle: "Pricing"
  },
  {
    page: "/signup",
    data: {
      subTitle: "",
      subTitleVisible: false,
      suMD: false,
      suTitle: ""
    },
    documentTitle: "Sign Up"
  },
  {
    page: "subscription/cancelled/[...stripe]",
    data: {
      subTitle: "SUBSCRIPTION",
      subTitleVisible: true,
      suMD: true,
      suTitle: ""
    }
  },
  {
    page: "subscription/success/[...stripe]",
    data: {
      subTitle: "SUBSCRIPTION",
      subTitleVisible: true,
      suMD: true,
      suTitle: ""
    }
  },
]

export default function Layout({ children }) {
  const location = useRouter()
  let data = headerJson.filter((item, index) => {
    if (location.pathname.includes(item?.page)) {
      return item
    }
  }).shift()?.data

  return (
    <>
      <DomainContextProvider>
        <Header subTitle={data?.subTitle} subTitleVisible={data?.subTitleVisible} suMD={data?.suMD} seoTag={data?.seoTag} suTitle={data?.suTitle} />
        <main>{children}</main>
        <Footer />
      </DomainContextProvider>
    </>
  )
}