import {
  Flex,
  Container,
  Heading,
  List,
  ListItem,
  Stack,
  Text,
  Link,
  ButtonGroup,
  IconButton,
  Box,
  SimpleGrid,
  Modal,
  ModalOverlay,
  ModalContent,
  ModalHeader,
  ModalBody,
  ModalCloseButton
} from "@chakra-ui/react"
import React, { useContext, useState, useEffect } from "react"
import { FaFacebook, FaLinkedin, FaTwitter, FaYoutube } from "react-icons/fa"
import NextLink from "next/link"
import { DomainContext } from "../context/DomainContext"
import ButtonPrimary from "../../components/buttons/PrimaryButton"
import ModalIframe from "../modalIframe/ModalIframe"
import { SUBSCRIBE_URL, SUPPORT_URL } from "../../utils/Constants"
import { CUSTOMER_SUBSCRIPTION } from "../../graphql/queries"
import { useAuth } from "../context/Auth"
import Client from "../../ApolloClient"

export default function Footer() {
  const { isSignedIn } = useAuth()
  const domainContextVal = useContext(DomainContext)
  const [subscribeModalShow, setSubscribeModalShow] = useState(false)
  const [iframeUrl, setIframeUrl] = useState("")
  const [heading, setHeading] = useState("")
  const [height, setHeight] = useState("480px")
  const [subscriptionName, setSubscriptionName] = useState()
  const [signedIn, setSignedIn] = useState(false)

  const redirectSellerSearch = () => {
    if (subscriptionName === "Enterprise") {
      window.location.href = "/sellers/search"
    }
    else {
      signedInModalOpen()
    }
  }

  const signedInModalOpen = () => {
    setSignedIn(true)
  }

  const signedInModalClose = () => {
    setSignedIn(false)
  }

  function ButtonMoreDetailsClick() {
    signedInModalClose()
  }

  const subscribeModalToggle = (url, heading, height) => {
    setIframeUrl(url)
    setHeading(heading)
    setHeight(height)
    setSubscribeModalShow(!subscribeModalShow)
  }

  const getSubscription = async () => {
    if (isSignedIn()) {
      const data = await Client.query({
        query: CUSTOMER_SUBSCRIPTION,
      })
      if (data?.data?.customerSubscription?.subscription?.plan?.id) {
        setSubscriptionName(data?.data?.customerSubscription?.subscription?.plan?.product?.name)
        return
      }
    }
  }

  useEffect(() => {
    getSubscription()

  }, [])

  return (
    <Box>
      <Flex
        bg="#000821"
        as="footer"
        align="center"
        justify="space-between"
        wrap="wrap"
        w="100%"
      >
        <Container maxW="container.xl">
          <Box
            as="footer"
            role="contentinfo"
            mx="auto"
            maxW="7xl"
            pt={{ base: "1.5rem", md: "1rem", lg: "2rem" }}
          >
            <Stack spacing="10">
              <Stack
                direction={{
                  base: "column",
                  lg: "row",
                }}
                spacing={{
                  base: "10",
                  lg: "28",
                }}
              >
                <SimpleGrid
                  display="grid"
                  columns={{ base: "2", md: "3", lg: "5" }}
                  minChildWidth={{ base: "208px", md: "248px", lg: "150px" }}
                  role="demo2"
                  w="100%"
                  spacing={{ base: "1.5rem", md: "1rem", lg: "4rem" }}
                >
                  <Box color="#fff">
                    <Heading
                      textStyle="primary"
                      fontSize="16px"
                      textTransform="uppercase"
                      mb={{ base: ".31rem", md: "1rem", lg: "1rem" }}
                    >
                      Platform
                    </Heading>
                    <List spacing={{ base: ".41rem", md: ".5rem", lg: ".5rem" }} fontSize={{ base: "13", md: "13", lg: "14" }}>
                      <NextLink href="/seller-leads" passHref>
                        <ListItem
                          cursor="pointer"
                          fontSize={{ base: "13", md: "13", lg: "13" }}
                          _hover={{
                            color: "#fc6414",
                            transition: "color .2s ease-in-out",
                          }}
                        >
                          Seller Leads
                        </ListItem>
                      </NextLink>

                      <NextLink href="/advanced-seller-search" passHref>
                        <ListItem
                          cursor="pointer"
                          fontSize={{ base: "13", md: "13", lg: "13" }}
                          _hover={{
                            color: "#fc6414",
                            transition: "color .2s ease-in-out",
                          }}
                        >
                          Advanced Seller Search
                        </ListItem>
                      </NextLink>
                      <NextLink href="/tracksellers-api" passHref>
                        <ListItem
                          cursor="pointer"
                          fontSize={{ base: "13", md: "13", lg: "13" }}
                          _hover={{
                            color: "#fc6414",
                            transition: "color .2s ease-in-out",
                          }}
                        >
                          Tracksellers  API
                        </ListItem>
                      </NextLink>

                      <NextLink href="/seller-intelligence-report" passHref>
                        <ListItem
                          cursor="pointer"
                          fontSize={{ base: "13", md: "13", lg: "13" }}
                          _hover={{
                            color: "#fc6414",
                            transition: "color .2s ease-in-out",
                          }}
                        >
                          Seller Intelligence Report
                        </ListItem>
                      </NextLink>

                      <NextLink
                        href={"/top-sellers-by-domain"}
                        passHref
                      >
                        <ListItem
                          cursor="pointer"
                          fontSize={{ base: "13", md: "13", lg: "13" }}
                          _hover={{
                            color: "#fc6414",
                            transition: "color .2s ease-in-out",
                          }}
                        >
                          Top Sellers By Domain
                        </ListItem>
                      </NextLink>

                      <NextLink
                        href={"/top-sellers-by-category"}
                        passHref
                      >
                        <ListItem
                          cursor="pointer"
                          fontSize={{ base: "13", md: "13", lg: "13" }}
                          _hover={{
                            color: "#fc6414",
                            transition: "color .2s ease-in-out",
                          }}
                        >
                          Top Sellers By Category
                        </ListItem>
                      </NextLink>
                      <NextLink href="https://resources.tracksellers.com/research/" passHref>
                        <ListItem
                          cursor="pointer"
                          fontSize={{ base: "13", md: "13", lg: "13" }}
                          _hover={{
                            color: "#fc6414",
                            transition: "color .2s ease-in-out",
                          }}
                        >
                          Research
                        </ListItem>
                      </NextLink>

                    </List>
                  </Box>
                  <Box color="#fff">
                    <Heading
                      textStyle="primary"
                      fontSize="16px"
                      textTransform="uppercase"
                      mb={{ base: ".51rem", md: "1rem", lg: "1rem" }}
                    >
                      Solutions
                    </Heading>
                    <List spacing={{ base: ".41rem", md: ".5rem", lg: ".5rem" }} fontSize={{ base: "13", md: "13", lg: "14" }}>
                      <NextLink href="/service-providers" passHref>
                        <ListItem
                          cursor="pointer"
                          fontSize={{ base: "13", md: "13", lg: "13" }}
                          _hover={{
                            color: "#fc6414",
                            transition: "color .2s ease-in-out",
                          }}
                        >
                          Service Providers
                        </ListItem>
                      </NextLink>

                      <NextLink href="/suppliers" passHref>
                        <ListItem
                          cursor="pointer"
                          fontSize={{ base: "13", md: "13", lg: "13" }}
                          _hover={{
                            color: "#fc6414",
                            transition: "color .2s ease-in-out",
                          }}
                        >
                          Suppliers
                        </ListItem>
                      </NextLink>

                      <NextLink href="/amazon-sellers" passHref>
                        <ListItem
                          cursor="pointer"
                          fontSize={{ base: "13", md: "13", lg: "13" }}
                          _hover={{
                            color: "#fc6414",
                            transition: "color .2s ease-in-out",
                          }}
                        >
                          Amazon Sellers
                        </ListItem>
                      </NextLink>

                      <NextLink href="/amazon-customers" passHref>
                        <ListItem
                          cursor="pointer"
                          fontSize={{ base: "13", md: "13", lg: "13" }}
                          _hover={{
                            color: "#fc6414",
                            transition: "color .2s ease-in-out",
                          }}
                        >
                          Amazon Customers
                        </ListItem>
                      </NextLink>

                    </List>
                  </Box>
                  <Box color="#fff">
                    <Heading
                      textStyle="primary"
                      fontSize="16px"
                      textTransform="uppercase"
                      mb={{ base: ".51rem", md: "1rem", lg: "1rem" }}
                    >
                      Seller Lists
                    </Heading>
                    <List spacing={{ base: ".41rem", md: ".5rem", lg: ".5rem" }} fontSize={{ base: "13", md: "13", lg: "14" }}>
                      <NextLink
                        href={`/sellers/by-domain/${domainContextVal.code}`}
                        passHref
                      >
                        <ListItem
                          cursor="pointer"
                          fontSize={{ base: "13", md: "13", lg: "13" }}
                          _hover={{
                            color: "#fc6414",
                            transition: "color .2s ease-in-out",
                          }}
                        >
                          Top Sellers By Domain
                        </ListItem>
                      </NextLink>

                      <NextLink
                        href={`/sellers/by-category/${domainContextVal.code}`}
                        passHref
                      >
                        <ListItem
                          cursor="pointer"
                          fontSize={{ base: "13", md: "13", lg: "13" }}
                          _hover={{
                            color: "#fc6414",
                            transition: "color .2s ease-in-out",
                          }}
                        >
                          Top Sellers By Category
                        </ListItem>
                      </NextLink>

                      <Text onClick={redirectSellerSearch}
                      >
                        <ListItem
                          cursor="pointer"
                          fontSize={{ base: "13", md: "13", lg: "13" }}
                          _hover={{
                            color: "#fc6414",
                            transition: "color .2s ease-in-out",
                          }}
                        >
                          Advanced Seller Search
                        </ListItem>
                      </Text>

                    </List>
                  </Box>
                  <Box color="#fff">
                    <Heading
                      textStyle="primary"
                      fontSize="16px"
                      textTransform="uppercase"
                      mb={{ base: ".51rem", md: "1rem", lg: "1rem" }}
                    >
                      Free Resources
                    </Heading>
                    <List spacing={{ base: ".41rem", md: ".5rem", lg: ".5rem" }} fontSize={{ base: "13", md: "13", lg: "14" }}>
                      <NextLink href="https://resources.tracksellers.com/research/" passHref>
                        <ListItem
                          cursor="pointer"
                          fontSize={{ base: "13", md: "13", lg: "13" }}
                          _hover={{
                            color: "#fc6414",
                            transition: "color .2s ease-in-out",
                          }}
                        >
                          Research
                        </ListItem>
                      </NextLink>

                      <NextLink href={"https://resources.tracksellers.com/articles/"} passHref>
                        <ListItem
                          cursor="pointer"
                          fontSize={{ base: "13", md: "13", lg: "13" }}
                          _hover={{
                            color: "#fc6414",
                            transition: "color .2s ease-in-out",
                          }}
                        >
                          Articles
                        </ListItem>
                      </NextLink>

                      <NextLink href={"https://resources.tracksellers.com/education/"} passHref>
                        <ListItem
                          cursor="pointer"
                          fontSize={{ base: "13", md: "13", lg: "13" }}
                          _hover={{
                            color: "#fc6414",
                            transition: "color .2s ease-in-out",
                          }}
                        >
                          Education

                        </ListItem>
                      </NextLink>



                    </List>
                  </Box>
                  <Box color="#fff" gridColumn={{ base: "span 2", md: "span 2", lg: "span 1" }}>
                    <Heading
                      textStyle="primary"
                      fontSize="16px"
                      textTransform="uppercase"
                      mb={{ base: ".51rem", md: "1rem", lg: "1rem" }}
                    >
                      About Us
                    </Heading>
                    <List spacing={{ base: ".41rem", md: ".5rem", lg: ".5rem" }} fontSize={{ base: "13", md: "13", lg: "14" }}>
                      <NextLink href="/about" passHref>
                        <ListItem
                          cursor="pointer"
                          fontSize={{ base: "13", md: "13", lg: "13" }}
                          _hover={{
                            color: "#fc6414",
                            transition: "color .2s ease-in-out",
                          }}
                        >
                          About Us
                        </ListItem>
                      </NextLink>


                      <ListItem
                        cursor="pointer"
                        fontSize={{ base: "13", md: "13", lg: "13" }}
                        _hover={{
                          color: "#fc6414",
                          transition: "color .2s ease-in-out",
                        }}
                        onClick={(event) => {
                          subscribeModalToggle(SUPPORT_URL, "Contact", "680px")
                        }}
                      >
                        Contact Us
                      </ListItem>
                      <NextLink href="/faq" passHref>
                        <ListItem
                          cursor="pointer"
                          fontSize={{ base: "13", md: "13", lg: "13" }}
                          _hover={{
                            color: "#fc6414",
                            transition: "color .2s ease-in-out",
                          }}
                        >
                          FAQ
                        </ListItem>
                      </NextLink>

                    </List>

                  </Box>
                  <Box color="#fff" gridColumn={{ base: "span 2", md: "span 2", lg: "span 1" }}>
                    <Heading
                      textStyle="primary"
                      fontSize="16px"
                      textTransform="uppercase"
                      mb={{ base: ".51rem", md: "1rem", lg: "1rem" }}
                    >
                      Pricing
                    </Heading>
                    <List spacing={{ base: ".41rem", md: ".5rem", lg: ".5rem" }} fontSize={{ base: "13", md: "13", lg: "14" }}>
                      <NextLink href="/pricing" passHref>
                        <ListItem
                          cursor="pointer"
                          fontSize={{ base: "13", md: "13", lg: "13" }}
                          _hover={{
                            color: "#fc6414",
                            transition: "color .2s ease-in-out",
                          }}
                        >
                          Pricing
                        </ListItem>
                      </NextLink>
                    </List>
                    <Box
                      w="100%"
                      textAlign="center"
                      borderTop="1px solid rgba(255, 255, 255,0.3)"
                      pt="15px"
                      mt="10px"
                    >
                      <ButtonPrimary
                        width={{
                          base: "100%",
                          sm: "100%",
                          md: "100%",
                          lg: "100%",
                          xl: "100%",
                        }}
                        ButtonClick={() => subscribeModalToggle(SUBSCRIBE_URL, "Subscribe to", "480px")}
                        minW="100%"
                        maxW={{
                          base: "100%",
                          sm: "340px",
                          md: "340px",
                          lg: "100%",
                          xl: "100%"
                        }}
                        buttonName="SUBSCRIBE"
                      />
                    </Box>
                    <ModalIframe
                      show={subscribeModalShow}
                      close={subscribeModalToggle}
                      heading={heading}
                      iframeUrl={iframeUrl}
                      height={height}
                    />
                  </Box>
                </SimpleGrid >
              </Stack>

              <Flex
                marginTop="15px !important"
                display={{ base: "flex", md: "flex", lg: "flex" }}
                direction={{ base: "column", md: "column", lg: "row" }}
                justifyContent="space-between"
                px={20}
                bg="#0051b6"
                p="10px 20px"
                borderTopLeftRadius={20}
                borderTopRightRadius={20}
                alignItems="center"
              >
                <Box mb={{ base: "5px", md: "20px", lg: "0px" }}>
                  <Text fontSize="sm" color="#80a8db">
                    &copy;2023 TrackSellers.com
                  </Text>
                </Box>

                <Box
                  flexDirection={{
                    base: "column",
                    sm: "column",
                    md: "row",
                    lg: "row",
                  }}
                  display={{ base: "flex", md: "flex", lg: "flex" }}
                  alignItems={{ base: "center", md: "center", lg: "center" }}
                  justifyContent="center"
                  gap={3}
                >
                  <Text
                    cursor="pointer"
                    color="#80a8db"
                    fontSize={{ base: "13", md: "13", lg: "14" }}
                    _hover={{
                      color: "#FFFFFF",
                      transition: "color .2s ease-in-out",
                    }}
                  >
                    <NextLink href="/terms-and-conditions">Terms of Service</NextLink>
                  </Text>

                  <ButtonGroup variant="ghost" color="black.600">
                    <IconButton
                      border="1px solid #80a8db"
                      background="transparent"
                      as="a"
                      href="https://www.facebook.com/tracksellers"
                      aria-label="facebook"
                      icon={<FaFacebook color="#80a8db" fontSize="20px" />}
                      target="_blank"
                    />
                    <IconButton
                      border="1px solid #80a8db"
                      background="transparent"
                      as="a"
                      href="https://twitter.com/track_sellers"
                      aria-label="twitter"
                      icon={<FaTwitter color="#80a8db" fontSize="20px" />}
                      target="_blank"
                    />
                    <IconButton
                      border="1px solid #80a8db"
                      background="transparent"
                      as="a"
                      href="https://www.youtube.com/channel/UCH47Q6ClT1sUlsm2dx9r1bAx"
                      aria-label="LinkedIn"
                      icon={<FaYoutube color="#80a8db" fontSize="20px" />}
                      target="_blank"
                    />
                    <IconButton
                      border="1px solid #80a8db"
                      background="transparent"
                      as="a"
                      href="https://www.linkedin.com/company/tracksellers"
                      aria-label="GitHub"
                      icon={<FaLinkedin color="#80a8db" fontSize="20px" />}
                      target="_blank"
                    />
                  </ButtonGroup>
                </Box>
              </Flex>
            </Stack>
          </Box>
        </Container>

        <Modal isCentered
          closeOnOverlayClick={false}
          isOpen={signedIn}
          onClose={signedInModalClose}
          size={"xl"}
          height="460px"
        >
          <ModalOverlay />
          <ModalContent p="15px" margin="15px" minH={{ base: "180px", md: "180px", xl: "180px", lg: "180px" }}>
            <ModalHeader p="0px">
              <Flex fontSize={{ base: "18px", md: "24px", lg: "24px" }} color="#243e63">
                {/* <Text>{heading}</Text> */}
                <Text color="rgb(3, 155, 229)" fontWeight="300">
                  TrackSellers.com
                </Text>
              </Flex>
            </ModalHeader>
            <ModalCloseButton top="1rem" color="#718096" />
            <ModalBody pt="15px" pb="0px" w="100%">
              <Text
                padding={{ base: "0", md: "0 2rem", lg: "0 2rem" }}
                textAlign="center" color="#243e63" fontSize={{ base: "1rem", md: "1rem", lg: "1.1rem" }}>
                This feature is available only for Enterprise users
              </Text>
              <Box width="100%" margin="15px 0 auto" textAlign="center">
                <NextLink href={"/pricing"} passHref>
                  <Link>
                    <ButtonPrimary buttonName="Pricing Plans" ButtonClick={ButtonMoreDetailsClick} />
                  </Link>
                </NextLink>
              </Box>
            </ModalBody>
          </ModalContent>
        </Modal >

      </Flex>
    </Box>
  )
}
