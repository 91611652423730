import {
  ApolloClient,
  ApolloLink,
  createHttpLink,
  InMemoryCache,
} from "@apollo/client"
import Hashids from "hashids"
import { getSession } from "next-auth/react"

const hashids = new Hashids("JACKNJILLWENUPTHEHILL")

const httpLink = createHttpLink({
  uri: process.env.NEXT_PUBLIC_API_URL,
})

const getSessionData = async (params = {}) => {
  try {
    const session = await getSession(params)
    if (session) return session?.id

    return null
  } catch (err) {
  }
}

const authLink = new ApolloLink(async (operation, forward) => {
  const nextRequestContext = operation.getContext().nextReqContext
  let sessionParams = {}
  if (nextRequestContext) {
    sessionParams = nextRequestContext
  }
  const sesssionData = await getSessionData(sessionParams)
  let extraHeaders = {
    tsauthtoken: hashids.encode(98282, +(new Date())),
  }
  if (sesssionData != null) {
    extraHeaders = {
      authorization: "JWT " + sesssionData,
      ...extraHeaders
    }
  }
  operation.setContext(({ headers }) => ({
    headers: {
      ...extraHeaders,
      ...headers
    }
  }));
  return forward(operation);
});

const Client = new ApolloClient({
  link: authLink.concat(httpLink),
  cache: new InMemoryCache(),
})

export default Client
