import {
  Flex,
  Text,
  Modal,
  ModalOverlay,
  ModalContent,
  ModalHeader,
  ModalBody,
  ModalCloseButton,
} from "@chakra-ui/react"
export default function ModalIframe({ show, close, heading, iframeUrl, height }) {
  return (
    <Modal isCentered
      closeOnOverlayClick={false}
      isOpen={show}
      onClose={close}
      size={"xl"}
      height="460px"
    >
      <ModalOverlay />
      <ModalContent margin="15px" height={{ base: "550px", md: "480px", xl: height, lg: height }}>
        <ModalHeader>
          <Flex fontSize={{ base: "18px", md: "24px", lg: "24px" }} color="#243e63">
            <Text>{heading}</Text>
            <Text pl="2" color="rgb(3, 155, 229)" fontWeight="300">
                TrackSellers.com
            </Text>
          </Flex>
        </ModalHeader>
        <ModalCloseButton />
        <ModalBody p={1} w="100%">
          <iframe
            width="100%"
            height="100%"
            src={iframeUrl}
          ></iframe>
        </ModalBody>
      </ModalContent>
    </Modal >
  )
}
