import AsyncSelect from "react-select/async"
import { components } from "react-select"
import { useContext, useRef } from "react"
import { DomainContext, DomainNameContext } from "../context/DomainContext.js"
import { Search2Icon } from "@chakra-ui/icons"
import { useRouter } from "next/router"
import { replaceUrlString } from "../../utils/Utils"
import { Box } from "@chakra-ui/react"
import Client from "../../ApolloClient"
import { SELLER_SEARCH_BY_NAME } from "../../graphql/queries.js"

export default function SellerSearchAutoComplete(props) {
    const router = useRouter()
    const myRef = useRef()
    const domainContextValue = useContext(DomainContext)
    const domainNameContextValue = useContext(DomainNameContext)
    const customStyles = {
        option: (provided, state) => ({
            ...provided,
            color: state.isSelected ? "#243e63" : "#718096",
            width: "100%",
            maxWidth: "300px",
            borderRadius: "7px",
            overflow: "hidden",
            marginBottom: "5px",
            padding: "6px 10px 6px 10px",
            cursor: "pointer"
        }),
        control: () => ({
            // none of react-select's styles are passed to <Control />
            width: "100%",
            display: "flex",
            flexDirection: "row",
            maxWidth: "315px",
            boxShadow: "0 3px 6px 0 rgb(140 152 164 / 25%)",
            backgroundColor: "#fff",
            borderRadius: "7px",
            overflow: "hidden",
            height: "50px"
        }),
        indicatorSeparator: (provided, state) => ({
            ...provided,
            display: "none"
        }),
        menu: (provided, state) => ({
            ...provided,
            width: "100%",
            maxWidth: "300px",
            boxShadow: "0 3px 6px 0 rgb(140 152 164 / 25%)",
            backgroundColor: "#fff",
            borderRadius: "7px",
            overflow: "hidden",
            border: "none",
            padding: "5px 5px 5px 5px",
            height: "200px",
            fontSize: "14px",
            zIndex: "1000 !important",
            marginTop: "-10px",
            // position: { base: "relative", md: "inherit", xl: "inherit", lg: "inherit" }
        }),
        singleValue: (provided, state) => {
            const opacity = state.isDisabled ? 0.5 : 1
            const transition = "opacity 300ms"
            return { ...provided, opacity, transition }
        },
        placeholder: (defaultStyles) => {
            return {
                ...defaultStyles,

                fontSize: "14px"
            }
        }
    }
    const handleInputChange = (val) => {
        return val
    }

    const handleOnChange = (val) => {
        props?.searchVal()
        router.push(`/sellers/${domainContextValue.code}/${val?.value}/${replaceUrlString(val?.label)}`)
    }

    const DropdownIndicator = props => {
        return (
            <components.DropdownIndicator {...props}>
                <Search2Icon />
            </components.DropdownIndicator>
        )
    }

    const searchloadOptions = async (inputVal, callback) => {
        try {
            const res = await Client.query({
                query: SELLER_SEARCH_BY_NAME,
                variables: {
                    domainCode: domainContextValue.code,
                    key: inputVal
                }
            })

            return res?.data?.sellerSearchByName.map((item) => {
                return {
                    label: item?.name,
                    value: item?.sellerId
                }
            })
        } catch (error) {

        }
    }

    return (
        <Box>
            <AsyncSelect
                instanceId={props?.instanceId}
                key={domainContextValue.code}
                defaultOptions
                styles={customStyles}
                loadOptions={searchloadOptions}
                onInputChange={handleInputChange}
                onChange={handleOnChange}
                components={{ DropdownIndicator }}
                placeholder={`Search Sellers In Amazon ${domainNameContextValue.name}`}
            />

        </Box>
    )
}
