import React, { createContext, useState } from "react"
// country code 
const DomainContext = createContext("")
// country code update function
const DomainDispatchContext = createContext("")
// country name 
const DomainNameContext = createContext("")
// country name update function
const DomainNameDispatchContext = createContext("")

// selected category 
const DomainCategoryContext = createContext("")
// selected category update function
const DomainCategoryDispatchContext = createContext("")

//  SelectedMenu 
const SelectedMenu = createContext("")
// SelectedMenu update function
const SelectedMenuDispatchContext = createContext("")



function DomainContextProvider({ children }) {
  // // country code  initialization
  const [currentDomain, setCurrentDomain] = useState({
    code: ""
  })

  // country name initialization
  const [currentDomainName, setCurrentDomainName] = useState({
    name: "",
  })

  // country selected category initialization
  const [currentDomainCategory, setCurrentDomainCategory] = useState({
    category: ""
  })

  const [menuName, setMenuName] = useState({
    name: ""
  })




  return (
    <DomainContext.Provider value={currentDomain}>
      <DomainDispatchContext.Provider value={setCurrentDomain}>
        <DomainNameContext.Provider value={currentDomainName}>
          <DomainNameDispatchContext.Provider value={setCurrentDomainName}>
            <DomainCategoryContext.Provider value={currentDomainCategory}>
              <DomainCategoryDispatchContext.Provider value={setCurrentDomainCategory}>
                <SelectedMenu.Provider value={menuName}>
                  <SelectedMenuDispatchContext.Provider value={setMenuName}>
                    {children}
                  </SelectedMenuDispatchContext.Provider>
                </SelectedMenu.Provider>
              </DomainCategoryDispatchContext.Provider>
            </DomainCategoryContext.Provider>
          </DomainNameDispatchContext.Provider>
        </DomainNameContext.Provider>
      </DomainDispatchContext.Provider>
    </DomainContext.Provider>
  )
}

export {
  DomainContextProvider,
  DomainContext,
  DomainDispatchContext,
  DomainNameContext,
  DomainNameDispatchContext,
  DomainCategoryContext,
  DomainCategoryDispatchContext,
  SelectedMenu,
  SelectedMenuDispatchContext
}