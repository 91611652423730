import { mode } from "@chakra-ui/theme-tools"


export const globalStyles = {
  colors: {
    gray: {
      700: "#1f2733",
    },
    teal: {
      500: "rgb(79, 209, 197)",
    },
  },
  styles: {
    global: (props) => ({
      body: {
        bg: mode("gray.50", "gray.800")(props),
        fontFamily: "Inter, sans-serif",
      },
      heading: {
        fontFamily: "Inter, sans-serif",
      },
      h2: {
        fontFamily: "Inter, sans-serif",
      },
      html: {
        fontFamily: "Inter, sans-serif",
      },
      card: {
        boxShadow: "rgb(140 152 164 / 25%) 0px 3px 6px 0px",
      },
    }),
  },
}
