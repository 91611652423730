import Head from "next/head"
import React from "react"

const Meta = ({ pageName, description, hrefLink }) => {
  return (
    <Head>
      <meta name="viewport" content="width=device-width, initial-scale=1" />
      <meta name="description" content={description ? description : "amazon marketplaces, marketplace, seller ratings, seller reviews, sellers, amazon, seller analytics, track sellers"} />
      <meta name="keywords" content={description ? description : "amazon marketplaces, marketplace, seller ratings, seller reviews, sellers, amazon, seller analytics, track sellers"} />
      <meta charSet="utf-8" />
      <meta name="facebook-domain-verification" content="8vb4x1645sfucu3en4y78p26dsgs0x" />
      <link rel="icon" href="/favicon.ico" />
      <link rel="canonical" href={hrefLink} />
      <title>{pageName ? pageName : "TrackSellers - Amazon Sellers Demystified"}</title>
    </Head>
  )
}

export default Meta