import { Button } from "@chakra-ui/react"

export default function ButtonPrimary(props) {
  const { buttonName, width, maxW } = props
  return (
    <Button
      bg="#f05605"
      color="#fff"
      fontSize="0.875rem"
      variant="no-hover"
      fontWeight="500"
      border-color="#f05605"
      padding={{ base: "0.5rem 1.2rem", md: "1rem 1.6rem", lg: "1rem 1.6rem" }}
      height="40px"
      w={width}
      maxW={maxW}
      onClick={() => {
        props?.ButtonClick()
      }}
    >
      {buttonName === "Get FREE Access" ? buttonName : buttonName.toUpperCase()}

    </Button>
  )
}
