import React, { useState, useEffect, useRef, useContext } from "react"
import { HamburgerIcon } from "@chakra-ui/icons"
import {
  Accordion,
  AccordionButton,
  AccordionIcon,
  AccordionItem,
  AccordionPanel,
  Box,
  Drawer,
  DrawerBody,
  DrawerCloseButton,
  DrawerContent,
  DrawerOverlay,
  Flex,
  HStack,
  List,
  Stack,
  Text,
  useColorModeValue,
  useDisclosure, useOutsideClick,
  Link,
  Button
} from "@chakra-ui/react"
import PropTypes from "prop-types"
import Flags from "country-flag-icons/react/3x2"
import { HSeparator } from "../separator/Separator"
import logoIcon from "../../public/logo.png"
import { useRouter } from "next/router"
import Image from "next/image"
import { ALL_DOMAINS } from "../../graphql/queries"
import Client from "../../ApolloClient"
import {
  DomainDispatchContext,
  DomainContext,
  DomainNameDispatchContext,
  DomainNameContext
} from "../context/DomainContext"
import styles from "../../styles/nav.module.css"
import SellerSearchAutoComplete from "../inputs/SellerSearchAutoComplete"
import ProfileMenu from "./ProfileMenu"
import { useAuth } from "../context/Auth"
import NextLink from "next/link"

export function SidebarResponsive(props) {
  let activeAccordionBg = useColorModeValue("white", "gray.700")
  let activeColor = useColorModeValue("gray.700", "white")
  let inactiveColor = useColorModeValue("gray.400", "gray.400")
  const location = useRouter()
  const ref = useRef(null)
  // this is for the rest of the collapses
  const mainPanel = React.useRef()
  let variantChange = "0.2s linear"
  const [countryOpen, setCountryOpen] = useState(false)
  const [allDomainData, setAllDomainData] = useState([])
  const [showScroll, setShowScroll] = useState(false)
  const domainDispatchContextVal = useContext(DomainDispatchContext)
  const domainContextVal = useContext(DomainContext)
  const domainNameVal = useContext(DomainNameContext)
  const domainNameDispatchContextVal = useContext(DomainNameDispatchContext)

  const { isSignedIn } = useAuth()

  /**
   * 
   */
  const loadingAllDomainsQuery = async () => {
    try {
      const { data, loading } = await Client.query({
        query: ALL_DOMAINS
      })
      if (loading === false && data) {
        setAllDomainData(data?.allDomains)
        return data?.allDomains
      }
    } catch (error) { }
  }

  useEffect(() => {
    loadingAllDomainsQuery()
  }, [])

  useEffect(() => {
    LocalStorageChaeck()
  }, [])

  const SearchValue = () => {
    onClose()
  }

  const CountryFlagClick = (code, name) => {
    localStorage.setItem("countryCode", code)
    localStorage.setItem("countryName", name)
    domainDispatchContextVal({ code: code })
    domainNameDispatchContextVal({ name: name })
    if (location.pathname === "/sellers/by-domain/[country]") {
      location.push({
        pathname: `/sellers/by-domain/${code}`,
      })
    }
    else if (location.pathname === "/sellers/by-category/[...slug]") {
      location.push({
        pathname: `/sellers/by-category/${code}`,
      })
    }
  }

  /**
 * If no domain selected by user default domain set as the first row value from allDomain query
 */
  const defaultDomain = async () => {
    let data = await loadingAllDomainsQuery()
    if (data?.length > 0) {
      domainNameDispatchContextVal({ name: data[0]?.name })
      domainDispatchContextVal({ code: data[0]?.code })
      localStorage.setItem("countryCode", data[0]?.code)
      localStorage.setItem("countryName", data[0]?.name)
    }
  }

  const LocalStorageChaeck = async () => {
    // checking countryCode key exist in local storage
    if (localStorage.hasOwnProperty("countryCode")) {
      // validating key value is Null or Undefind
      if (localStorage.getItem("countryCode") == undefined || localStorage.getItem("countryCode") == null ||
        localStorage.getItem("countryCode") == "" || localStorage.getItem("countryCode") == "undefined") {
        defaultDomain()
      } else {
        await loadingAllDomainsQuery()
        domainNameDispatchContextVal({ name: localStorage.getItem("countryName") })
        domainDispatchContextVal({ code: localStorage.getItem("countryCode") })
      }
      // if key dosn"t exist setting USA as default
    } else {
      await defaultDomain()
    }
  }

  /**
  * Update Flags component when user chage domain or
  * when the page renders
  * @param {string} code country code 
  * else Component render in dom
  * @returns Flags Component
  */
  const flagupdate = (code) => {
    if (allDomainData.length > 0 && domainContextVal.code) {
      // Nav bar flag button
      let Flag = Flags["US"]
      // changing country code uk to gb, In country-flag-icons/react/3x2 npm package uk code is GB
      Flag = Flags[code.toUpperCase() == "UK" ? "GB" : code.toUpperCase()]
      return (
        <Flag className=""
          style={{ width: "30px", marginRight: "5px" }}
          key={domainContextVal.code}
        />
      )
    }
  }

  const handleScroll = () => {
    if (window.pageYOffset > 60) {
      setShowScroll(true)
    } else {
      setShowScroll(false)
    }
  }

  useEffect(() => {
    window.addEventListener("scroll", handleScroll)
    return () => window.removeEventListener("scroll", handleScroll)
  }, [])

  const handleHideDropdown = (event) => {
    if (event.key === "Escape") {
      setCountryOpen(false)
    }
  }

  const handleClickOutside = event => {
    if (ref.current && ref.current.id == "FLS") {
    }
    ref.current = null
  }

  useOutsideClick({
    ref: ref,
    handler: () => setCountryOpen(false),
  })

  const routeHome = () => {
    onClose()
    location.push({
      pathname: "/",
    })
  }
  useEffect(() => {
    document.addEventListener("keydown", handleHideDropdown, true)
    document.addEventListener("click", handleClickOutside, true)
    return () => {
      document.removeEventListener("keydown", handleHideDropdown, true)
      document.removeEventListener("click", handleClickOutside, true)
    }
  }, [countryOpen])


  // verifies if routeName is the one active (in browser input)
  const activeRoute = (routeName) => {
    return location.pathname.includes(routeName)
  }

  const { logoText, routes } = props

  let hamburgerColor = useColorModeValue("gray.500", "gray.200")

  if (props.secondary === true) {
    hamburgerColor = "white"
  }

  var brand = (
    <Box pt={"35px"} mb="8px">
      <Box mx={7} flex="1" justifyContent="center" onClick={() => { activeRoute("/") }}>
        <NextLink href="/" passHref>
          <Link
            target="_blank"
            display="flex"
            lineHeight="100%"
            mb="15px"
            justifyContent="flex-start"
            alignItems="center"
            fontSize="11px"
          >
            <Image width="180px" height="32px" src={logoIcon} mr="0px" alt="tracksellers.com logo" />
          </Link>
        </NextLink>
      </Box>
      <NextLink href="/" passHref>
        <Link
          target="_blank"
          display="flex"
          lineHeight="100%"
          mb="0px"
          justifyContent="center"
          alignItems="center"
          fontSize="11px"
        >
          <Text fontSize="xs" mt="3px">
            {logoText}
          </Text>
        </Link>
      </NextLink>
      <HSeparator />
      {/* <Box padding="1rem"><SearchInput /></Box> */}
    </Box>
  )

  // SIDEBAR
  const { isOpen, onOpen, onClose } = useDisclosure()
  const btnRef = React.useRef()

  // Color variables
  return (
    <Box ref={mainPanel} display={props.display}>
      <Box ml="1rem" display="flex">
        {
          isSignedIn() == true && <ProfileMenu />
        }

        {
          allDomainData.length > 0
            ? <Flex
              justify="center"
              align="center"
              _hover={{
                background: "transparent",
                padding: "5px",
                borderRadius: "6px"
              }}
              padding="5px"
              borderRadius="6px"
              onClick={(event) => {
                setCountryOpen(!countryOpen)
              }}
            >
              {flagupdate(domainContextVal.code)}

              <Box height="auto" fontSize="12px" whiteSpace="nowrap" className={showScroll === true ? styles.FontDark : styles.FontLight} >
                Amazon {domainNameVal.name}
                {/* <DragHandleIcon w={5} h={4} id="COU" />   Amazon {currentCountryName} */}
              </Box>
            </Flex>
            : null
        }
        <Flex>
          <HamburgerIcon
            color={hamburgerColor}
            w="25px"
            h="30px"
            mt="2px"
            ms="10px"
            ref={btnRef}
            colorscheme="teal"
            cursor="pointer"
            onClick={onOpen}
          />
          <Drawer
            placement="left"
            isOpen={isOpen}
            onClose={onClose}
            finalFocusRef={btnRef}
          >
            <DrawerOverlay />
            <DrawerContent
              w="295px"
              maxW="295px"
              ms={{
                sm: "16px",
              }}
              my={{
                sm: "16px",
              }}
              borderRadius="16px"
            >
              <DrawerCloseButton
                _focus={{ boxShadow: "none" }}
                _hover={{ boxShadow: "none" }}
              />
              <DrawerBody maxW="295px" px="0rem">
                <Box maxW="100%" h="100%">
                  <Box mb="0px" onClick={onClose}>{brand}</Box>
                  <Stack direction="column" mb="0px" p="0px">
                    <Box color="gray.500" fontWeight="400">
                      <Box p="10px" >
                        <SellerSearchAutoComplete searchVal={SearchValue} px={4} mt={3} mb={2} instanceId={"sideBarSearch"} />
                      </Box>
                      {
                        routes.map((prop, index) => {
                          if (prop.collapse) {
                            return (
                              <Accordion allowToggle key={index}>
                                <AccordionItem border="none">
                                  <AccordionButton
                                    pt="5px"
                                    pb="5px"
                                    display="flex"
                                    align="center"
                                    justify="center"
                                    key={index}
                                    borderRadius="15px"
                                    _focus={{ boxShadow: "none" }}
                                    _hover={{ boxShadow: "none" }}
                                    px={null}
                                    py={"12px"}
                                    bg={
                                      activeRoute(prop.path)
                                        ? activeAccordionBg
                                        : "transparent"
                                    }
                                  >
                                    {
                                      activeRoute(prop.path) ? (
                                        <Flex
                                          p="0px"
                                          justifyContent="flex-start"
                                          alignItems="center"
                                          bg="transparent"
                                          mx={{ xl: "auto", }}
                                          px="0px"
                                          borderRadius="15px"
                                          w="100%"
                                          height="2rem"
                                        >
                                          <Text
                                            color={activeColor}
                                            my="auto"
                                            fontSize="sm"
                                            display={"block"}
                                          >
                                            {prop.name}
                                          </Text>
                                        </Flex>
                                      ) : (
                                        <Flex
                                          justifyContent="flex-start"
                                          alignItems="center"
                                          bg="transparent"
                                          mx={{
                                            xl: "auto",
                                          }}
                                          px="0px"
                                          borderRadius="15px"
                                          w="100%"
                                          height="2rem"
                                        >
                                          <Text my="auto" color="#243e63" fontWeight="400" fontSize="1.025rem">
                                            {prop.name}
                                          </Text>
                                        </Flex>
                                      )}
                                    <AccordionIcon color="gray.400" />
                                  </AccordionButton>
                                  <AccordionPanel pe={null} pb="0px" pt="0px">
                                    <List>
                                      {
                                        prop?.items?.map((xitem, xindex) => {
                                          return (
                                            <NextLink href={xitem?.path ? xitem?.path : "#"} passHref key={`sNlink${xindex}`}>
                                              <Link
                                                key={xindex}
                                                onClick={(e) => {
                                                  if (xitem?.action) {
                                                    xitem?.action()
                                                    e.preventDefault()
                                                  }
                                                }}
                                              >
                                                <Box>
                                                  <HStack spacing="14px" py="8px" px="8px">
                                                    <Text
                                                      color={
                                                        activeRoute(xitem?.path?.toString()?.toLowerCase())
                                                          ? activeColor
                                                          : inactiveColor
                                                      }
                                                      fontWeight={activeRoute(xitem?.name) ? "normal" : "normal"}
                                                      fontSize="0.91rem"
                                                      cursor="pointer"
                                                      onClick={onClose}
                                                    >
                                                      {xitem?.name}
                                                    </Text>
                                                  </HStack>
                                                </Box>
                                              </Link>
                                            </NextLink>
                                          )
                                        })
                                      }
                                    </List>
                                  </AccordionPanel>
                                </AccordionItem>
                              </Accordion>
                            )
                          }
                          else if (prop?.publicMenu && prop?.smScreenViw) {
                            if (location.pathname.includes(prop?.name.toLowerCase())) {
                              return (
                                <Box
                                  py="8px" px="16px"
                                  display="flex"
                                  justifyContent="center"
                                  alignItems="center"
                                  flex={1}
                                  width="100%"
                                  key={index}
                                >
                                  <Button
                                    padding="0"
                                    key={index}
                                    fontWeight="500"
                                    height="32px"
                                    border="1px solid #f05605"
                                    color="#fff"
                                    lineHeight="32px"
                                    bg="#f05605"
                                    width="100%"
                                    transition="all 0.6s"
                                    fontSize="0.775rem"
                                    _active={{
                                      background: "#f05605",
                                      color: "#fff",
                                      transition: "0.6s"
                                    }}
                                    _hover={{
                                      background: "#f05605",
                                      color: "#fff",
                                      transition: "0.6s"
                                    }}
                                    onClick={prop?.action}
                                  >
                                    <NextLink href={prop?.path} passHref>
                                      <Link padding="0 15px" height="32px" w="100%" lineHeight="32px" >{prop?.name}</Link>
                                    </NextLink>
                                  </Button>
                                </Box>
                              )
                            } else {
                              return (
                                <Box
                                  py="8px" px="16px"
                                  display="flex"
                                  justifyContent="center"
                                  alignItems="center"
                                  flex={1}
                                  width="100%"
                                  key={index}
                                >
                                  <NextLink href={prop?.path} passHref>
                                    <Link
                                      width="100%">
                                      <Button
                                        border="1px solid #f05605"
                                        color="#f05605"
                                        bg="#fff"
                                        fontSize="0.975rem"
                                        width="100%"
                                        fontWeight="400"
                                        _active={{
                                          background: "transparent",
                                        }}
                                        _hover={{
                                          background: "transparent",
                                        }}
                                        onClick={prop?.action}
                                      >
                                        {prop?.name}
                                      </Button>
                                    </Link>
                                  </NextLink>
                                </Box>
                              )
                            }
                          }



                        })
                      }
                    </Box>
                  </Stack>
                </Box>
              </DrawerBody>
            </DrawerContent>
          </Drawer>
        </Flex>
        {countryOpen &&
          <Box
            onMouseLeave={(event) => { setCountryOpen(true) }}
            bg="#fff"
            minh={300}
            zIndex={3}
            position="fixed"
            borderRadius={10}
            top={75}
            right={10}
            width={260}
            boxShadow="rgb(0 0 0 / 5%) 0px 0px 10px 0px"
            transition=" all 0.5s ease-in-out"
            overflow="hidden"
            ref={ref} id="FLS"
          >
            {
              allDomainData.length > 0
                ? <Flex padding="10px" display={{ base: "flex", md: "flex", lg: "flex" }}
                  direction={{ base: "row", md: "column", lg: "row" }}
                  flexWrap={{ base: "wrap", md: "wrap", lg: "wrap" }}
                  justify="center"
                >
                  {
                    allDomainData?.map((item, index) => {
                      let Flag = Flags["US"]
                      // changing country code uk to gb, In country-flag-icons/react/3x2 npm package uk code is GB
                      Flag = Flags[item?.code.toUpperCase() == "UK" ? "GB" : item?.code.toUpperCase()]
                      return (
                        <Box bg="#fff"
                          _hover={{ bg: "#e8f0fe", borderRadius: "6px" }}
                          padding="1rem 1.2rem"
                          alignItems="center"
                          width="80px" height="80px"
                          id="TOYP" flexDirection="column"
                          display="flex"
                          flex-direction="column"
                          onClick={(event) => { CountryFlagClick(item?.code, item?.name) }}
                          key={index}
                        >
                          <Flag
                            title="United States"
                            style={{ width: "30px" }}
                            onClick={() => setCountryOpen(false)}
                          />
                          <Text color="#718096" fontSize=".9rem" mt="0.5rem" fontWeight="400" id="TOYP">{item?.name}</Text>
                        </Box>
                      )
                    })
                  }
                </Flex>
                : null
            }
          </Box>
        }
      </Box>
    </Box>
  )
}

SidebarResponsive.propTypes = {
  logoText: PropTypes.string,
  routes: PropTypes.arrayOf(PropTypes.object),
}

export default SidebarResponsive