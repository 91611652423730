import { extendTheme } from "@chakra-ui/react"
import { globalStyles } from "./Styles"
import { font } from "./foundations/Fonts"
import { breakpoints } from "./foundations/Breakpoints"
import { buttonStyles } from "./components/Button"
import { badgeStyles } from "./components/Badge"
import { linkStyles } from "./components/Link"
import { drawerStyles } from "./components/Drawer"
import { CardComponent } from "./additions/card/Card"
import { CardBodyComponent } from "./additions/card/CardBody"
import { CardFooterComponent } from "./additions/card/CardFooter"
import { CardHeaderComponent } from "./additions/card/CardHeader"
//import { MainPanelComponent } from "./additions/layout/MainPanel"
//import { PanelContentComponent } from "./additions/layout/PanelContent"
//import { PanelContainerComponent } from "./additions/layout/PanelContainer"
import { Texttheme } from "./additions/TextStyle"

// import { mode } from "@chakra-ui/theme-tools"
export default extendTheme(
  { breakpoints }, // Breakpoints
  globalStyles,
  font, // Global styles
  buttonStyles, // Button styles
  badgeStyles, // Badge styles
  linkStyles, // Link styles
  drawerStyles, // Sidebar variant for Chakra's drawer
  CardComponent, // Card component
  CardBodyComponent, // Card Body component
  CardFooterComponent, // Card Footer component
  CardHeaderComponent, // Card Header component
  //MainPanelComponent, // Main Panel component
  //PanelContentComponent, // Panel Content component
  //PanelContainerComponent, // Panel Container component
  Texttheme
)
