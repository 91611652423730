import {
  Box,
  Flex,
  Text,
  Menu,
  MenuButton,
  MenuList,
  MenuItem,
  MenuOptionGroup,
  MenuItemOption,
  Button,
  Heading,
  useOutsideClick,
  Link,
  Modal,
  ModalOverlay,
  ModalContent,
  ModalHeader,
  ModalBody,
  ModalCloseButton
} from "@chakra-ui/react"
import { ChevronDownIcon } from "@chakra-ui/icons"
import React, { useState, useEffect, useContext, useRef } from "react"
import Flags from "country-flag-icons/react/3x2"
import Image from "next/image"
import { SidebarResponsive } from "../sidebar/Sidebar"
import { useRouter } from "next/router"
import styles from "../../styles/nav.module.css"
import InnerLogo from "../../public/white-logo.png"
import logoIcon from "../../public/default-logo.png"
import ButtonPrimary from "../buttons/PrimaryButton"
import { Container } from "@chakra-ui/react"
import {
  DomainDispatchContext,
  DomainContext,
  DomainNameDispatchContext,
  DomainNameContext,
  DomainCategoryContext,
  SelectedMenu,
  SelectedMenuDispatchContext
} from "../context/DomainContext"
import Client from "../../ApolloClient"
import { Search2Icon } from "@chakra-ui/icons"
import SellerSearchAutoComplete from "../inputs/SellerSearchAutoComplete"
import ModalIframe from "../modalIframe/ModalIframe"
import { SUBSCRIBE_URL, SUPPORT_URL } from "../../utils/Constants"
import { useAuth } from "../context/Auth"
import { ALL_DOMAINS, CUSTOMER_SUBSCRIPTION } from "../../graphql/queries"
import ProfileMenu from "../sidebar/ProfileMenu"
import NextLink from "next/link"

export default function Header({
  subTitle,
  suMD,
  suTitle,
  seoTag,
  subTitleVisible,
  logo,
  logoText,
  secondary,
  ...rest
}) {
  const location = useRouter()
  const ref = useRef(null)
  const searchBoxRef = useRef(null)
  const domainDispatchContextVal = useContext(DomainDispatchContext)
  const domainContextVal = useContext(DomainContext)
  const SelectedMenuDispatchContextVal = useContext(SelectedMenuDispatchContext)
  const SelectedMenuVal = useContext(SelectedMenu)
  const domainNameVal = useContext(DomainNameContext)
  const domainNameDispatchContextVal = useContext(DomainNameDispatchContext)
  const domainCategoryContextval = React.useContext(DomainCategoryContext)
  const [showScroll, setShowScroll] = useState(false)
  const [navTextColor, setNavTextColor] = useState("black")
  const [isOpen, setIsOpen] = useState(false)
  const [btnClicked, setbtnClicked] = useState(false)
  const [allDomainData, setAllDomainData] = useState([])
  const [searchOpen, setSearchOpen] = useState(false)
  const [subscribeModalShow, setSubscribeModalShow] = useState(false)
  const [iframeUrl, setIframeUrl] = useState("")
  const [heading, setHeading] = useState("")
  const [height, setHeight] = useState("480px")
  const [subscriptionName, setSubscriptionName] = useState()

  const { isSignedIn, signOutUser, userInfo } = useAuth()

  /**
   * This function toggle subscribe modal 
   */
  const subscribeModalToggle = (url, heading, height) => {
    setIframeUrl(url)
    setHeading(heading)
    setHeight(height)
    setSubscribeModalShow(!subscribeModalShow)
  }

  // adding advancedFilter menu to top menu bar
  const UpdateMenu = () => {
    const urls = [
      {
        name: "Top Sellers By Domain",
        path: `/sellers/by-domain/${domainContextVal.code}`,
      },
      {
        name: "Top Sellers By Category",
        path: `/sellers/by-category/${domainContextVal.code}`,
      },
      {
        name: "Advanced Seller Search",
        action: () => {
          subscriptionName === "Enterprise" ? window.location.href = "/sellers/search" : signedInModalOpen()
        },
      }
    ]

    return urls
  }
  const UpdateProductMenu = () => {
    const urls = [
      {
        name: "Seller Leads",
        path: "/seller-leads",
      },
      {
        name: "Advanced Seller Search",
        path: "/advanced-seller-search",
      },
      {
        name: "Tracksellers API",
        path: "/tracksellers-api",
      },
      {
        name: "Seller Intelligence Report",
        path: "/seller-intelligence-report",
      },
      {
        name: "Top Sellers By Domain",
        path: "/top-sellers-by-domain",
      },
      {
        name: "Top Sellers By Category",
        path: "/top-sellers-by-category",
      },
      {
        name: "Research",
        path: "https://resources.tracksellers.com/research/",
      }

    ]

    return urls
  }
  const [navbarMenus, setNavbarMenus] = useState([])
  const [signedIn, setSignedIn] = useState(false)

  const signedInModalOpen = () => {
    setSignedIn(true)
  }

  const signedInModalClose = () => {
    setSignedIn(false)
  }

  function ButtonMoreDetailsClick() {
    signedInModalClose()
  }

  const getSubscription = async () => {
    if (isSignedIn()) {
      const data = await Client.query({
        query: CUSTOMER_SUBSCRIPTION,
      })
      if (data?.data?.customerSubscription?.subscription?.plan?.id) {
        setSubscriptionName(data?.data?.customerSubscription?.subscription?.plan?.product?.name)
        return
      }
    }
  }

  useEffect(() => {
    setNavbarMenus([])
    setNavbarMenus([{
      name: "Platform",
      path: "/sellers/by-domain/us/",
      collapse: true,

      one_liner: false,
      items: UpdateProductMenu(),
    },
    {
      name: "Solutions",
      path: "/dashboard",
      collapse: true,
      one_liner: true,
      items: [
        {
          name: "Service Providers",
          path: "/service-providers",
          text: "Target Amazon sellers to benefit from your services"
        },
        {
          name: "Suppliers",
          path: "/suppliers",
          text: "Monitor health of your customers"
        },
        {
          name: "Amazon Sellers",
          path: "/amazon-sellers",
          text: "Insights on your competition"
        },
        {
          name: "Amazon Customers ",
          path: "/amazon-customers",
          text: "Know who you are buying from"
        }
      ],
    },
    {
      name: "Seller Lists",
      path: "/dashboard",
      collapse: true,
      one_liner: false,
      items: UpdateMenu(),
    },
    {
      name: "Free Resources",
      path: "/dashboard",
      collapse: true,
      one_liner: false,
      items: [
        {
          name: "Research",
          path: "https://resources.tracksellers.com/research/",
        },
        {
          name: "Articles",
          path: "https://resources.tracksellers.com/articles/",
        },
        {
          name: "Education",
          path: "https://resources.tracksellers.com/education/",
        }

      ],
    },
    {
      name: "About Us",
      path: "/dashboard",
      collapse: true,
      one_liner: false,
      items: [
        {
          name: "About Us",
          path: "/about",
        },


        {
          name: "Contact Us",
          action: () => {
            subscribeModalToggle(SUPPORT_URL, "Contact", "680px")
          }
        },
        {
          name: "FAQ",
          path: "/faq",
        },
        {
          name: "Subscribe",
          action: () => {
            subscribeModalToggle(SUBSCRIBE_URL, "Subscribe to", "480px")
          }
        },
      ],
    },
    {
      name: "Pricing",
      path: "/pricing",
      collapse: false,
      publicMenu: true,
      outlineButton: true,
      component: (item, index) => { return buttonMenu(item, index) },
      smScreenViw: true,
      lgScreenViw: true

    },
    {
      name: "Sign In",
      path: "/api/auth/signin",
      collapse: false,
      publicMenu: !isSignedIn(),
      outlineButton: true,
      component: (item, index) => { return buttonMenu(item, index) },
      smScreenViw: true,
      lgScreenViw: true
    },
    {
      name: "Profile",
      collapse: false,
      publicMenu: isSignedIn(),
      outlineButton: true,
      component: (item, index) => { return (<ProfileMenu key={"profileMenu"} />) },
      smScreenViw: false,
      lgScreenViw: true
    }
    ])
  }, [subscriptionName, domainContextVal.code, isSignedIn])


  const buttonMenu = (item, index) => {
    return (
      // Selected button menu
      <NextLink href={item?.path} passHref key={`menuBtnLink${index}`}>
        <Link lineHeight="normal" key={`menuBtn${index}`}>
          <Button
            className={SelectedMenuVal?.name == item?.name ? styles.activeMenuButton : styles.inactiveMenuButton}
            onClick={() => {
              SelectedMenuDispatchContextVal({ name: item?.name })
              if (!item?.action) return null
              item?.action()
            }}
            width={{ base: "100%", md: "auto", lg: "auto", xl: "auto" }}
            fontWeight={{ base: "400", md: "500", md: "500", lg: "500", xl: "500" }}
          >
            {item?.name}
          </Button>
        </Link>
      </NextLink>

    )
  }


  useEffect(() => {
    getSubscription()
    window.addEventListener("scroll", handleScroll)
    return () => window.removeEventListener("scroll", handleScroll)
  }, [])

  /**
   *
   * @param {string} title
   * @returns sub heading component
   */
  const subHeadingTextget = (title) => {
    let marketplaceUrl = ""
    let name = ""
    allDomainData?.map((item, index) => {
      if (item?.code == domainContextVal.code) {
        marketplaceUrl = item?.amazonUrl
        name = item?.name
      }
    })
    if (location?.pathname.includes("sellers/by-domain/")) {
      return (
        <Heading
          color="#243e63"
          padding="10px 0px "
          w={{ base: "100%", md: "100%", lg: "100%" }}
          fontSize={{ base: "1.2rem", md: "2rem", lg: "2rem" }}
        >
          <span>Top Sellers In </span>
          <span style={{ color: "#ff9800" }}>
            {name} - {marketplaceUrl}
          </span>{" "}
          <span>Marketplace</span>
        </Heading>
      )
    } else {
      return (
        <Heading
          color="#243e63"
          padding="10px 0px "
          w={{ base: "100%", md: "100%", lg: "100%" }}
          fontSize={{ base: "1.2rem", md: "2rem", lg: "2rem" }}
        >
          <span>Top Sellers In </span>
          <span style={{ color: "#ff9800" }}>
            {domainCategoryContextval?.category}
          </span>{" "}
          <span>Category on {marketplaceUrl}</span>
        </Heading>
      )
    }
  }

  /**
   *
   */
  const loadingAllDomainsQuery = async () => {
    try {
      const { data, loading } = await Client.query({
        query: ALL_DOMAINS,
      })
      if (loading === false && data) {
        setAllDomainData(data?.allDomains)
        return data?.allDomains
      }
    } catch (error) { }
  }

  useEffect(() => {
    // calling domain picker fuction to validate current selected domain
    getLocalStorage()
  }, [domainContextVal.code])

  /**
   * If no domain selected by user default domain set as the first row value from allDomain query
   */
  const defaultDomain = async () => {
    let data = await loadingAllDomainsQuery()
    if (data?.length > 0) {
      domainNameDispatchContextVal({ name: data[0]?.name })
      domainDispatchContextVal({ code: data[0]?.code })
      localStorage.setItem("countryCode", data[0]?.code)
      localStorage.setItem("countryName", data[0]?.name)
    }
  }

  /**
   *
   */
  const getLocalStorage = async () => {
    // checking countryCode key exist in local storage
    if (localStorage.hasOwnProperty("countryCode")) {
      // validating key value is Null or Undefind
      if (
        localStorage.getItem("countryCode") == undefined ||
        localStorage.getItem("countryCode") == null ||
        localStorage.getItem("countryCode") == "" ||
        localStorage.getItem("countryCode") == "undefined"
      ) {
        defaultDomain()
      } else {
        await loadingAllDomainsQuery()
        domainNameDispatchContextVal({
          name: localStorage.getItem("countryName"),
        })
        domainDispatchContextVal({ code: localStorage.getItem("countryCode") })
      }
      // if key dosn"t exist setting USA as default
    } else {
      await defaultDomain()
    }
  }

  const SearchValue = () => {
    setSearchOpen(false)
  }

  useOutsideClick({
    ref: ref,
    handler: () => {
      setIsOpen(false)
    },
  })

  useOutsideClick({
    ref: searchBoxRef,
    handler: () => {
      setSearchOpen(false)
    },
  })

  useEffect(() => {
    document.addEventListener("keydown", handleHideDropdown, true)
    document.addEventListener("click", handleClickOutside, true)
    return () => {
      document.removeEventListener("keydown", handleHideDropdown, true)
      document.removeEventListener("click", handleClickOutside, true)
    }
  }, [isOpen, domainContextVal.code])

  const handleScroll = () => {
    if (window.pageYOffset > 60) {
      setShowScroll(true)
      setNavTextColor("white")
    } else {
      setShowScroll(false)
      setNavTextColor("black")
    }
  }

  const handleHideDropdown = (event) => {
    if (event.key === "Escape") {
      setIsOpen(false)
    }
  }

  const handleClickOutside = (event) => {
    if (ref.current && ref.current.id == "FLS") {
    }
    ref.current = null
  }

  /**
   * Update Flags component when user chage domain or
   * when the page renders
   * @param {string} code country code
   * else Component render in dom
   * @returns Flags Component
   */
  const flagupdate = (code) => {
    if (allDomainData?.length > 0 && domainContextVal?.code) {
      // Nav bar flag button
      let Flag = Flags["US"]
      // changing country code uk to gb, In country-flag-icons/react/3x2 npm package uk code is GB
      Flag = Flags[code.toUpperCase() == "UK" ? "GB" : code.toUpperCase()]
      return (
        <Flag
          className=""
          style={{ width: "30px", marginRight: "5px" }}
          key={domainContextVal.code}
        />
      )
    }
  }

  const CountryFlagClick = (code, name) => {
    localStorage.setItem("countryCode", code)
    localStorage.setItem("countryName", name)
    domainDispatchContextVal({ code: code })
    domainNameDispatchContextVal({ name: name })
    setIsOpen(false)
    if (location.pathname === "/sellers/by-domain/[country]") {
      location.push({
        pathname: `/sellers/by-domain/${code}`,
      })
    } else if (location.pathname === "/sellers/by-category/[...slug]") {
      location.push({
        pathname: `/sellers/by-category/${code}`,
      })
    } else if (location.pathname === "/sellers/search") {
      location.push({
        pathname: "/sellers/search",
      })
    }
  }

  function MouseOverCountry() {
    setIsOpen(true)
  }

  function MouseLeaveCountry() {
    setIsOpen(false)
  }

  return (
    <>
      <Box>
        <Box
          zIndex={3}
          className={
            showScroll === true ? styles.ScrollDark : styles.ScrollLight
          }
        >
          <Container maxW="container.xl" position="relative">
            <Flex
              p="15px 0px"
              as="nav"
              align="center"
              justify="space-between"
              wrap="wrap"
              w="100%"
            >
              <Box
                display={{ base: "block", md: "flex" }}
                direction={["column", "row", "row", "row"]}
                justifyContent="space-between"
                width="100%"
              >
                <Flex
                  align="center"
                  justify={[
                    "space-between",
                    "space-between",
                    "flex-end",
                    "flex-end",
                  ]}
                  direction={["row", "row", "row", "row"]}
                  mr={[0, 20, 20, 20]}
                  w={{ base: "100%", md: "auto" }}
                >
                  <NextLink href="/" passHref>
                    <Link>
                      <Box width={{ base: "140px", md: "180px", lg: "180px" }}>
                        <Image
                          width="180px"
                          height="34px"
                          src={showScroll === true ? InnerLogo : logoIcon}
                          cursor="pointer"
                          mr="0px"
                          alt="tracksellers.com logo"
                        />
                      </Box>
                    </Link>
                  </NextLink>
                  <Box
                    ms={{ base: "auto", lg: "0px" }}
                    display={{ lg: "none", md: "none" }}
                  >
                    <SidebarResponsive
                      logoText={logoText}
                      secondary={secondary}
                      routes={navbarMenus}
                      subscribeModalToggle={subscribeModalToggle}
                      logo={logo}
                      {...rest}
                    />
                  </Box>
                </Flex>
                {/* Leftside Section */}
                <Flex
                  align="center"
                  justify={["center", "space-between", "flex-end", "flex-end"]}
                  direction={["column", "row", "row", "row"]}
                  display={{ base: "none", md: "flex" }}
                >
                  <Flex gridTemplateColumns="repeat(6, 1fr)" gap={2}>
                    {navbarMenus?.map((item, index) => {
                      if (item?.collapse === true) {
                        return (
                          <Menu key={index} closeOnSelect={true}>
                            <MenuButton
                              height="32px"
                              className={
                                showScroll === true
                                  ? styles.FontDark
                                  : styles.FontLight
                              }
                              ps={{ base: ".1rem", md: "0.1rem", lg: ".51rem" }}
                              pe={{ base: ".1rem", md: "0.1rem", lg: ".51rem" }}
                              fontSize={{
                                base: "0.775rem",
                                md: "0.875rem",
                                lg: "0.875rem",
                                xl: "0.975rem",
                              }}
                              _active={{
                                background: "transparent",
                              }}
                              _hover={{
                                background: "transparent",
                              }}
                              fontWeight="500"
                              as={Button}
                              borderRadius={0}
                              bg="transparent"
                              rightIcon={<ChevronDownIcon w={6} h={6} />}
                            >
                              {item?.name}{item?.one_liner}
                            </MenuButton>


                            <MenuList
                              bg="#fff"
                              fontSize="0.875rem"
                              lineHeight="1.8rem"
                              border="none"
                              mt="0px"
                              p={3}
                              boxShadow="0 1px 20px rgb(0 0 0 / 13%)"

                            >
                              {item?.one_liner ? (

                                <MenuOptionGroup title=""
                                >
                                  <Text value="liner" className={styles.MenuNestText} >For</Text>
                                </MenuOptionGroup>
                              ) : null
                              }
                              {item?.items?.map((subItem, subIndex) => {
                                return (
                                  <NextLink href={subItem?.path ? subItem?.path : "#"} passHref key={`nlink-${subIndex}`} >
                                    <Link
                                      fontSize="0.875rem"
                                      fontWeight="500"
                                      color="#677788"

                                      onClick={(e) => {
                                        if (subItem?.action) {
                                          subItem?.action()
                                          e.preventDefault()
                                        }
                                      }}
                                      key={`link-${subIndex}`}
                                    >
                                      {!item?.one_liner ? (
                                        <MenuItem>
                                          <Text
                                            mb={{
                                              base:
                                                item?.items?.length - 1 ===
                                                  subIndex
                                                  ? 0
                                                  : 8,
                                              sm: 0,
                                            }}
                                            mr={{
                                              base: 0,
                                              sm:
                                                item?.items?.length - 1 ===
                                                  subIndex
                                                  ? 0
                                                  : 8,
                                            }}
                                            display="block"
                                          >
                                            {subItem?.name}{item.one_liner}
                                          </Text>

                                        </MenuItem>
                                      ) : item?.one_liner ? (

                                        <MenuOptionGroup title={subItem.name} type='checkbox'
                                        ><MenuItemOption value='liner' className={styles.MenuSubText}>{subItem.text}</MenuItemOption>

                                        </MenuOptionGroup>
                                      ) : null
                                      }
                                    </Link>
                                  </NextLink>
                                )
                              })}
                            </MenuList>

                          </Menu>

                        )
                      }
                      else if ((item?.publicMenu) && (item?.lgScreenViw)) {
                        return item?.component(item, index)
                      }

                    })}
                    {/* Rightside Section */}
                    <Flex
                      display={{ base: "none", md: "flex" }}
                      cursor="pointer"
                      onClick={(event) => {
                        //setIsOpen(!isOpen)
                      }}
                    >
                      <Box
                        m="0rem .5rem"
                        display="flex"
                        onClick={(event) => {
                          setbtnClicked(!btnClicked)
                          setIsOpen(!isOpen)
                        }}
                      >
                        {allDomainData?.length > 0 ? (
                          <Flex
                            justify="center"
                            align="center"
                            _hover={{
                              background: "transparent",
                              borderRadius: "6px",
                            }}
                            padding="0px"
                            borderRadius="6px"
                            key={domainContextVal.code}
                          >
                            {flagupdate(domainContextVal.code)}
                            {/*<Flags.t title="United States" className="" style={{ width: "30px", marginRight:"5px" }} />*/}
                            <Box
                              height="auto"
                              fontSize="14px"
                              whiteSpace="nowrap"
                              className={
                                showScroll === true
                                  ? styles.FontDark
                                  : styles.FontLight
                              }
                            >
                              Amazon {domainNameVal.name}
                              {/* <DragHandleIcon w={5} h={4} id="COU" />    */}
                            </Box>
                          </Flex>
                        ) : null}
                      </Box>
                    </Flex>
                  </Flex>
                  <Box m="0rem 0.5rem" className={styles.SearchScrollIcon}>
                    <Search2Icon
                      cursor="pointer"
                      className={
                        showScroll === true ? styles.FontDark : styles.FontLight
                      }
                      onClick={() => setSearchOpen(!searchOpen)}
                    />
                  </Box>
                </Flex>
              </Box>
            </Flex>
            {searchOpen ? (
              <Box className={styles.SearchBoxNav} pt="5px" ref={searchBoxRef}>
                <SellerSearchAutoComplete
                  searchVal={SearchValue}
                  instanceId={"headerSellerSearch"}
                />
              </Box>
            ) : null}
            {isOpen && (
              <Box
                onMouseLeave={(event) => {
                  setIsOpen(true)
                }}
                bg="#fff"
                maxh={300}
                zIndex={3}
                position="absolute"
                borderRadius={10}
                top={75}
                right="15px"
                width={260}
                boxShadow="rgb(0 0 0 / 5%) 0px 0px 10px 0px"
                transition=" all 0.5s ease-in-out"
                overflow="hidden"
                ref={ref}
                id="FLS"
              >
                {allDomainData?.length > 0 ? (
                  <Flex
                    padding="10px"
                    display={{ base: "flex", md: "flex", lg: "flex" }}
                    direction={{ base: "row", md: "row", lg: "row" }}
                    flexWrap={{ base: "wrap", md: "wrap", lg: "wrap" }}
                    justify="center"
                  >
                    {allDomainData?.map((item, index) => {
                      let Flag = Flags["US"]
                      // changing country code uk to gb, In country-flag-icons/react/3x2 npm package uk code is GB
                      Flag =
                        Flags[
                        item?.code.toUpperCase() == "UK"
                          ? "GB"
                          : item?.code.toUpperCase()
                        ]
                      return (
                        <Box
                          bg="#fff"
                          _hover={{ bg: "#e8f0fe", borderRadius: "6px" }}
                          cursor="pointer"
                          padding="1rem 1.2rem"
                          alignItems="center"
                          width="80px"
                          height="80px"
                          flexDirection="column"
                          display="flex"
                          flex-direction="column"
                          onClick={(event) => {
                            CountryFlagClick(item?.code, item?.name)
                          }}
                          key={index}
                        >
                          <Flag
                            title="United States"
                            style={{ width: "30px" }}
                          />
                          <Text
                            color="#718096"
                            fontSize=".9rem"
                            mt="0.5rem"
                            fontWeight="400"
                            id="TOYP"
                          >
                            {item?.name}
                          </Text>
                        </Box>
                      )
                    })}
                  </Flex>
                ) : null}
              </Box>
            )}
          </Container>
        </Box >
        {subTitleVisible && (
          <Box className={styles.InnerBg}>
            <Container maxW="container.xl">
              <Flex
                display="flex"
                justifyContent="center"
                align="start"
                direction="column"
                mt="0px"
                textAlign="left"
              >
                {location?.pathname.includes("sellers/by-domain/") ||
                  location?.pathname.includes("sellers/by-category/") ? (
                  subHeadingTextget(subTitle)
                ) : (seoTag) ? (
                  <Heading
                    as={"h1"}
                    color="#243e63"
                    padding="10px 0px "
                    w={{ base: "100%", md: "100%", lg: "60%" }}
                    fontSize={{ base: "2rem", md: "2rem", lg: "2rem" }}
                  >
                    {subTitle}
                  </Heading>
                )
                  : (
                    <Heading
                      color="#243e63"
                      padding="10px 0px "
                      w={{ base: "100%", md: "100%", lg: "60%" }}
                      fontSize={{ base: "2rem", md: "2rem", lg: "2rem" }}
                    >
                      {subTitle}
                    </Heading>
                  )
                }
                {suMD && (
                  <Heading
                    color="#243e63"
                    padding="0px "
                    w={{ base: "100%", md: "100%", lg: "100%" }}
                    fontSize="1.2rem"
                    fontWeight="400"
                  >
                    {suTitle}
                  </Heading>
                )}
              </Flex>
            </Container>
          </Box>
        )
        }
      </Box >

      <Modal isCentered
        closeOnOverlayClick={false}
        isOpen={signedIn}
        onClose={signedInModalClose}
        size={"xl"}
        height="460px"
      >
        <ModalOverlay />
        <ModalContent p="15px" margin="15px" minH={{ base: "180px", md: "180px", xl: "180px", lg: "180px" }}>
          <ModalHeader p="0px">
            <Flex fontSize={{ base: "18px", md: "24px", lg: "24px" }} color="#243e63">
              {/* <Text>{heading}</Text> */}
              <Text color="rgb(3, 155, 229)" fontWeight="300">
                TrackSellers.com
              </Text>
            </Flex>
          </ModalHeader>
          <ModalCloseButton top="1rem" color="#718096" />
          <ModalBody pt="15px" pb="0px" w="100%">
            <Text
              padding={{ base: "0", md: "0 2rem", lg: "0 2rem" }}
              textAlign="center" color="#243e63" fontSize={{ base: "1rem", md: "1rem", lg: "1.1rem" }}>
              This feature is available only for Enterprise users
            </Text>
            <Box width="100%" margin="15px 0 auto" textAlign="center">
              <NextLink href={"/pricing"} passHref>
                <Link>
                  <ButtonPrimary buttonName="Pricing Plans" ButtonClick={ButtonMoreDetailsClick} />
                </Link>
              </NextLink>
            </Box>
          </ModalBody>
        </ModalContent>
      </Modal >

      <ModalIframe
        show={subscribeModalShow}
        close={subscribeModalToggle}
        heading={heading}
        iframeUrl={iframeUrl}
        height={height}
      />
    </>
  )
}
