const CardFooter = {
  baseStyle: {
    display: "flex",
    width: "100%",
  },
}

export const CardFooterComponent = {
  components: {
    CardFooter,
  },
}
