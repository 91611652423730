import { gql } from "@apollo/client"

export const LOGIN = gql`
  mutation  tokenAuth($username: String!, $password: String!){
    tokenAuth(username: $username, password: $password){
      token
      refreshToken
    }
  }
  `
export const SIGNUP = gql`
  mutation  register($email: String!, $password1: String!, $password2: String!,$username: String! ,$firstName: String!, $lastName: String!){
    register(email: $email, password1: $password1, password2: $password2, username: $username ,firstName: $firstName ,lastName: $lastName){
        success
        errors
        token
      
    }
  }
  `
export const REFRESH_SELLER_DATA = gql`
  mutation createSeller($domainCode: String!, $sellerId: String!){
    createSeller(domainCode: $domainCode, sellerId: $sellerId) {
      seller {
        id
        name
        hasFba
        listings
        lastUpdated
        reviewsLifetime
        listingsAsOfDate
        categories {
          category {
            name
          }
          productCountPct
        }
        domain{
          name
          amazonUrl
          code
          currencyCode
          currencySymbol
        }
        brands {
          brand
          productCountPct
        }
        timeSeries {
          asOfDate
          reviews
        }
        info {
          country {
            name
          }
          state
          logoImage
        }
        stats {
          rankLifetime
          rank12m
          reviews1m
          reviews12m
          reviewsLastDate
          rating1m
          rating12m
          asOfDate
          score1m
          ordersEst12m
          salesEst12m
        }
        similarSellers {
          similarSeller {
            name
            id
            domain{
              code
              name
              amazonUrl
            }
            info{
              logoImage
            }
            stats {
              rank12m
              reviews12m
            }
            mainCategory {
              name
            }
          }
        }
      }
    }
  }  
  `
export const FORGET_PASSWORD = gql`
  mutation  sendPasswordResetEmail($email: String!){
    sendPasswordResetEmail(email: $email){
    success,
    errors
    }
  }
  `
export const RESET_PASSWORD = gql`
  mutation  passwordReset($token: String!
    $newPassword1: String!
    $newPassword2: String!){
    passwordReset(token: $token, newPassword1: $newPassword1, newPassword2: $newPassword2){
    success,
    errors
    }
  }
  `
export const REFRESH_TOKEN = gql`
mutation   refreshToken($refreshToken:String!){
  refreshToken(refreshToken:$refreshToken){
    token,
    payload,
    refreshToken
  }
}
`
export const SELLER_REPORT_CHECKOUT_SESSION = gql`
  mutation   createStripeSellerReportCheckoutSession($countryCode:String!,$successUrl:String!, $cancelUrl:String! ){
    createStripeSellerReportCheckoutSession(countryCode: $countryCode, successUrl: $successUrl, cancelUrl: $cancelUrl){
      checkoutSession {
        id
        url
        liveMode
      }
    }
  }
  `
export const STRIPE_SESSION = gql`
  mutation  createStripeCheckoutSession($cancelUrl: String!, $priceId: String!, $successUrl: String!){
    createStripeCheckoutSession(cancelUrl: $cancelUrl, priceId: $priceId, successUrl: $successUrl){
      checkoutSession {
        id
        url
        liveMode
      }
    }
  }
  `
export const CUSTOMER_PORTAL_SESSION = gql`mutation 
    createStripePortalSession($returnUrl: String!) {
    createStripePortalSession(returnUrl: $returnUrl) {
      portalSession {
        id
        url
        liveMode
      }
    }
  \
  }`

export const REVOKE_TOKEN = gql`
  mutation revokeToken($refreshToken:String!){
    revokeToken(refreshToken:$refreshToken){
      revoked
    }
  }
  `

export const DOWNLOAD_NOTIFICATION = gql`
  mutation DownloadNotification($pageUrl: String!, $parameters: GenericScalar!) {
    downloadNotification(pageUrl: $pageUrl, parameters: $parameters) {
      message
    }
  }
  `
