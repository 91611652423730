import { orderBy } from "lodash"
import Client from "../ApolloClient"

import { STRIPE_PRICE_LIST, CUSTOMER_SUBSCRIPTION, USER_INFO, SUBSCRIPTION_FEATURES_With_PLANS } from "../graphql/queries"
import { CUSTOMER_PORTAL_SESSION } from "../graphql/mutations"

/**
   * Fuction will filter array and return cancatinated string of fist 2 elements from array
   * @param {Array} arr input array
   * @param {String} formate  Input array key name
   * @param {String} sortOrder Array sorting format
   * @returns String
   */
export const arrayFilter = (arr, formate, sortOrder) => {
  try {
    if (arr?.length > 0 && arr != undefined) {
      // result array
      let matchedValues = []
      // sorting array in desc order
      // chcking the length of brand name (item.brand) to remove brands with name like `-, ?` etc
      switch (formate) {
        case "brand":
          matchedValues = orderBy(arr, "productCountPct", sortOrder)?.filter((item) => item.brand.length > 2)
          break
        case "category":
          matchedValues = orderBy(arr, "productCountPct", sortOrder)?.filter((item) => item?.category?.name.length > 2)
          break
      }
      if (matchedValues.length > 1) {
        return formate === "brand"
          ? matchedValues[0]?.brand + ", " + matchedValues[1]?.brand
          : [{ "name": matchedValues[0]?.category?.name, "id": matchedValues[0]?.category?.id }, { "name": matchedValues[1]?.category?.name, "id": matchedValues[1]?.category?.id }]
      } else {
        return formate === "brand" ? matchedValues[0]?.brand : [{ "name": matchedValues[0]?.category?.name, "id": matchedValues[0]?.category?.id }]
      }
    }
  } catch (error) {

  }
}

export const replaceUrlString = (str) => {
  try {
    return str?.replaceAll(" ", "-").toLowerCase()
  } catch (error) {

  }
}

/**
   * Function will check if string is valid
   * @param {string} value
   * @return actual string or "" 
   */
export const nullValidations = (value) => {
  try {
    if (value === undefined || value === null || value === "") {
      return ("")
    } else {
      return (value)
    }
  } catch (error) {

  }
}

/**
 * 
 * @param {*} arr 
 */
export const getUniqueByName = (arr) => {
  return _.uniqBy(arr, "name")
}

/**
 * Checks if the script is running in client side
 * @returns true / false
 */
export function isClientSide() {
  return typeof window !== "undefined"
}

export async function getUserInfo() {
  try {
    const data = await Client.query({
      query: USER_INFO,
    })
    if (data && data.data && data.data.me) {
      return data.data.me
    }
  }
  catch (err) {
    console.log(err)
  }
}
export async function getStripeProducts() {

  const data = await Client.query({
    query: SUBSCRIPTION_FEATURES_With_PLANS,
  })
  if (data.data) return data.data

  return null
}
export async function retrieveSubScription() {
  try {
    const data = await Client.query({
      query: CUSTOMER_SUBSCRIPTION,
    })
    return {
      subscriptionData: data.data.customerSubscription.subscription.plan.product,
      subscriptionFeatures: data.data.customerSubscription.subscriptionFeatures,
      subscriptionExists: data.data.customerSubscription.subscriptionExists
    }

  } catch (err) {
    return {
      subscriptionData: null
    }
  }
}
export async function retrieveCustomerPortalSession() {
  try {
    const data = await Client.mutate({
      mutation: CUSTOMER_PORTAL_SESSION,
      variables: {
        returnUrl: window.location.origin
      }
    })
   
    return data?.data?.createStripePortalSession?.portalSession
  } catch (err) {
    console.log(err)
  }
}

