import { extendTheme } from "@chakra-ui/react"

// 2. Extend the theme with new layer styles
export const Texttheme = extendTheme({
  textStyles: {
    h1: {
      // you can also use responsive styles
      fontSize: ["14px", "14px"],
      fontWeight: "bold",
      lineHeight: "110%",
      letterSpacing: "-2%",
    },
    h2: {
      fontSize: ["36px", "48px"],
      fontWeight: "semibold",
      lineHeight: "110%",
      letterSpacing: "-1%",
    },
  },
})