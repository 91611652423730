import { ChakraProvider } from "@chakra-ui/react"
import theme from "../theme/Theme"
import { ApolloProvider } from "@apollo/react-hooks"
import Client from "../ApolloClient"
import NProgress from "nprogress"
import "nprogress/nprogress.css"
import "../styles/global.css"
import Router from "next/router"
import Script from "next/script"
import { AuthProvider } from "../components/context/Auth"
import Layout from "../components/layout/Layout"
import { SessionProvider } from "next-auth/react"
import "@stripe/stripe-js"
import Meta from "../components/meta/Meta"

export default function MyApp({ Component, pageProps }) {

  NProgress.configure({
    minimum: 0.3,
    easing: "ease",
    speed: 800,
    showSpinner: false,
  })

  Router.events.on("routeChangeStart", () => NProgress.start())
  Router.events.on("routeChangeComplete", () => NProgress.done())
  Router.events.on("routeChangeError", () => NProgress.done())

  const isProduction = process.env.NODE_ENV === "production"
  const GA_TRACKING_ID = process.env.NEXT_PUBLIC_GA_TRACKING_ID

  const getLayout = Component.getLayout || ((page) =>
    <Layout>
      {page}
    </Layout>
  )

  return (
    <>
      <Meta pageName={"TrackSellers - Amazon Sellers Demystified"}></Meta>
      {
        isProduction && process?.env?.NEXT_PUBLIC_ENABLE_GA == "true"
        &&
        <>
          <Script
            strategy="afterInteractive"
            src={`https://www.googletagmanager.com/gtag/js?id=${GA_TRACKING_ID}`}
          />
          <Script
            strategy="afterInteractive"
            id="gajsScript"
          >
            {
              `
              window.dataLayer = window.dataLayer || []
              function gtag(){dataLayer.push(arguments)}
              gtag("js", new Date())
              gtag("config", "${GA_TRACKING_ID}")`
            }
          </Script>
        </>
      }
      <Script
        strategy="afterInteractive"
        id="chatBotScript">
        {
          `
          (function() {
            window.sib = { equeue: [], client_key: "3tvlu8lmdzdatq03po2i6g3h" };
            /* OPTIONAL: email to identify request*/
            // window.sib.email_id = 'example@domain.com';
            /* OPTIONAL: to hide the chat on your script uncomment this line (0 = chat hidden; 1 = display chat) */
            // window.sib.display_chat = 0;
            // window.sib.display_logo = 0;
            /* OPTIONAL: to overwrite the default welcome message uncomment this line*/
            // window.sib.custom_welcome_message = 'Hello, how can we help you?';
            /* OPTIONAL: to overwrite the default offline message uncomment this line*/
            // window.sib.custom_offline_message = 'We are currently offline. In order to answer you, please indicate your email in your messages.';
            window.sendinblue = {}; for (var j = ['track', 'identify', 'trackLink', 'page'], i = 0; i < j.length; i++) { (function(k) { window.sendinblue[k] = function(){ var arg = Array.prototype.slice.call(arguments); (window.sib[k] || function() { var t = {}; t[k] = arg; window.sib.equeue.push(t);})(arg[0], arg[1], arg[2]);};})(j[i]);}var n = document.createElement("script"),i = document.getElementsByTagName("script")[0]; n.type = "text/javascript", n.id = "sendinblue-js", n.async = !0, n.src = "https://sibautomation.com/sa.js?key=" + window.sib.client_key, i.parentNode.insertBefore(n, i), window.sendinblue.page();
          })();
          `
        }
      </Script>

      {/* Linkedin Tag */}
      <Script strategy="afterInteractive" id="linkedinTag">
        {`
        _linkedin_partner_id = '3998892';
        window._linkedin_data_partner_ids = window._linkedin_data_partner_ids || [];
        window._linkedin_data_partner_ids.push(_linkedin_partner_id);
        (function(l) {
        if (!l){window.lintrk = function (a, b) { window.lintrk.q.push([a, b]) };
            window.lintrk.q=[]}
            var s = document.getElementsByTagName("script")[0];
            var b = document.createElement("script");
            b.type = "text/javascript";b.async = true;
            b.src = "https://snap.licdn.com/li.lms-analytics/insight.min.js";
           s.parentNode.insertBefore(b, s);})(window.lintrk);`
        }
      </Script>
      <ApolloProvider client={Client}>
        <ChakraProvider theme={theme} overflow="scroll">
          <SessionProvider>
            <AuthProvider>
              {getLayout(<Component {...pageProps} />)}
            </AuthProvider>
          </SessionProvider>
        </ChakraProvider>
      </ApolloProvider>
    </>
  )
}