import { gql } from "@apollo/client"

export const SELLER_SEARCH_BY_NAME = gql`
  query SellerSearchByName($domainCode: String!, $key: String!) {
    sellerSearchByName(domainCode: $domainCode, key: $key, n:5) {
      sellerId
      name
    }
  }`

export const DOMAIN = gql`
  query Domain($code: String!) {
    domain(code: $code) {
      name
      id
      code
      amazonUrl
    }
  }`

export const CATEGORIES_FOR_DOMAIN = gql`
  query categoriesForDomain($domainCode: String!){
    categoriesForDomain(domainCode: $domainCode) {
      name
      id
    }
  }`

export const ALL_DOMAINS = gql`
  query {
    allDomains {
      name
      id
      code
      amazonUrl
    }
    countries {
      name
      isoCode2
    }
  }
  `
export const SELLER_DETAILS = gql`
  query seller($domainCode: String!, $sellerId: String!){
    seller(domainCode: $domainCode, sellerId: $sellerId) {
      id
      name
      hasFba
      listings
      listingsAsOfDate
      lastUpdated
      reviewsLifetime
      categories {
        id
        productCountPct
        category {
        id
        name
        }
      }
      domain{
        name
        amazonUrl
        code
      }
      stats {
        reviews12m
        rankLifetime
        rank12m
        reviews1m
        reviews12m
        reviewsLastDate
        rating1m
        rating12m
        asOfDate
        score1m
      }
      similarSellers {
        similarSeller {
          name
          id
          domain{
            code
            name
            amazonUrl
          }
          info{
            logoImage
          }
          stats {
            rank12m
            reviews12m
          }
          mainCategory {
            name
          }
        }
      }
      brands {
        brand
        productCountPct
      }
      timeSeries {
        asOfDate
        reviews
      }
      info {
        businessName
        country{
        name
        }
        state
        logoImage
      }
    }

  }`

export const TOP_SELLERS_BY_CATEGORY = gql`
  query  topSellersByCategory($domainCode: String!, $categoryId: Float!) {
    topSellersByCategory(domainCode: $domainCode, categoryId: $categoryId, n: 20) {
      sellerId
      name
      rank
      rating
      mainCategory
    }
  }`
export const TOP_SELLERS_BY_DOMAIN = gql`
  query topSellersByDomain($domainCode: String!) {
    topSellersByDomain(domainCode: $domainCode,n:10000) {
      sellerId
      name
      rank
      rating
      mainCategory
    }
  }`
export const USER_INFO = gql`
      query {
        me {
          username
          firstName
          lastName
        }
      }`
export const SELLER_DETAILS_AND_ALL_DOMAIN = gql`
  query seller($domainCode: String!, $sellerId: String!){
    seller(domainCode: $domainCode, sellerId: $sellerId) {
      id
      name
      hasFba
      listings
      listingsAsOfDate
      lastUpdated
      reviewsLifetime
      categories {
        id
        productCountPct
        category {
        id
        name
        }
      }
      domain{
        name
        amazonUrl
        code
        currencyCode
        currencySymbol
      }
      stats {
        reviews12m
        rankLifetime
        rank12m
        reviews1m
        reviews12m
        reviewsLastDate
        rating1m
        rating12m
        asOfDate
        score1m
        ordersEst12m
        salesEst12m
      }
      similarSellers {
        similarSeller {
          name
          id
          domain{
            code
            name
            amazonUrl
          }
          info{
            logoImage
          }
          stats {
            rank12m
            reviews12m
          }
          mainCategory {
            name
          }
        }
      }
      brands {
        brand
        productCountPct
      }
      timeSeries {
        asOfDate
        reviews
      }
      info {
        businessName
        country{
        name
        }
        state
        logoImage
      }
    }
    allDomains {
      name
      id
      code
      amazonUrl
      currencyCode
      currencySymbol
    }
    countries {
      name
      isoCode2
    }
  }`


export const SELLER_DETAILS_MINIMAL = gql`
  query seller($domainCode: String!, $sellerId: String!){
    seller(domainCode: $domainCode, sellerId: $sellerId) {
      id
      name
    }

  }`

export const STRIPE_CONFIG = gql`
  query stripeConfig {
    	stripeConfig {
        liveMode
        publicKey
      }
  }
  `
export const CUSTOMER_SUBSCRIPTION = gql`query {
    customerSubscription {
      subscription {
        id
        plan {
          id
          description
          product {
            id
            name
          }
        }
      }
      subscriptionExists
      subscriptionFeatures    
    }
  }`
export const STRIPE_PRICE_LIST = gql`query {
    stripePricings {
      id
      name 
      planSet {
        id
        interval
        amount
        humanReadablePrice
        currency
      }
    }
  }`
export const SUBSCRIPTION_FEATURES_With_PLANS = gql`query {
         
  subscriptionPlans {
    name
    stripePriceId
    features
    amountWithCurrency
  }
  
  subscriptionFeatures
}`
